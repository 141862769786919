import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Introduction = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 10vw;

    flex-wrap: wrap;

    width: 100%;
    padding: 10vw 0;
    text-align: center;
    z-index: inherit;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 5vw 0;
        flex-direction: row;
        gap: 5vw;
    }
`;

export const FlexButton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;

    /* Tablet: height */
    @media (min-width: 641px)  {
        flex-direction: row;
        gap: 2em;
    }
`;

export const IntroductionText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1vw;

    /* Tablet: height */
    @media (min-width: 641px)  {
        width: 40vw;
    }
`;

export const Aanmelden = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10vw;

    text-align: center;

    // background-color: ${props => props.color || 'white'};
    width: 100%;

    padding: 10vw 0;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 5vw 0;
        gap: 5vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        gap: 3vw;
    }
`

export const Informatie = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 10vw;

    width: 100%;
    background-color: #F1E3D4;

    padding: 10vw 0vw;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 5vw 0;
        gap: 3vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        gap: 3vw;
    }
`;

export const Downloadable = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10vw;

    /* Tablet: height */
    @media (min-width: 641px)  {
        gap: 5vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        gap: 4vw;
    }
`;

export const VideoContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 5vw;

    padding: 10vw 0;
    background-color: #F1E3D4;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 5vw 0;
        gap: 3vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        gap: 2vw;
    }
`;