import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Video, ReactIcon, Button } from '../../components';
import { GeneralContext } from '../../App';
import * as Papa from 'papaparse';

export default function PodcastEpisode(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const params = useParams();

    const [ episodes, setEpisodes ] = useState([]);
    const [ episode, setEpisode ] = useState({});

    const episodeId = params.podcastId;

    const parsePodcastCsv = (data, columns) => {
        let dataRows = data.data;

        let newEpisodes = []
        for (let i = 1; i < dataRows.length; i++) {
            let row = dataRows[i];
            let dataObj = {}
            for (let j = 0; j < row.length; j++) {
                dataObj[columns[j]] = row[j];
            }
            newEpisodes.push(dataObj);
        }

        setEpisodes(newEpisodes);
        
        if (!isNaN(episodeId) && Number(episodeId) < newEpisodes.length) {
            setEpisode(newEpisodes[Number(episodeId)]);
        }
        
    }

    useEffect(() => {
        if (episodes.length === 0) {
            fetch('../../podcast.csv')
                .then( response => response.text() )
                .then( responseText => {
                    const data = Papa.parse(responseText);
                    
                    const columns = data['data'][0];
                    parsePodcastCsv(data, columns)
                })
        }
    }, [episodes]);

    // checks if numeric
    if (Object.keys(episode).length === 0 || isNaN(episodeId)) {
        return <div>Geen podcast gevonden</div>;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '5vw' }}>
            <Typography type='h2' style={{ margin: '0 0 1vw 0', color: '#E8A97D', maxWidth: hasPhone ? '80vw' : '50vw' }}>#{episode.nummer} {episode.naam}</Typography>
            <Typography style={{ fontFamily: 'Amelie', fontSize: '20px', margin: '0 0 2vw 0'}}>Borstvoedingsbabbel voor {episode.type}</Typography>
            <Typography style={{ maxWidth: hasPhone ? '80%' : '50%', margin: '0 0 2vw 0' }}>{episode.inleidingtekst}</Typography>
            <div style={{ maxWidth: hasPhone ? '100vw' : '90vw', zIndex: 3}}>
                <Video videoId={episode.youtube} />
            </div>
            <div style={{ display: 'flex', gap: '1vw', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginTop: hasPhone && '5vw' }} 
                onClick={() => window.open(`https://www.youtube.com/watch?v=${episode.youtube}`, '_blank')}
            >
                <ReactIcon name='youtube' circleFill='#913211' noHover={true} noLink={true} />
                <Typography style={{ margin: '0.5vw 0 0 0'}}>BEKIJK HIER DE PODCASTS OP YOUTUBE</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '2vw', margin: '0 0 3vw 0', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', gap: '1vw', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} 
                    onClick={() => window.open(`https://${episode.spotify}`, '_blank')}
                >
                    <ReactIcon name='spotify' circleFill='#913211' noHover={true} noLink={true} />
                    <Typography style={{ margin: '0.5vw 0 0 0'}}>BELUISTER OP SPOTIFY</Typography>
                </div>
                <div style={{ display: 'flex', gap: '1vw', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} 
                    onClick={() => window.open(`https://${episode.itunes}`, '_blank')}
                >
                    <ReactIcon name='itunes' circleFill='#913211' noHover={true} noLink={true} />
                    <Typography style={{ margin: '0.5vw 0 0 0'}}>BELUISTER OP APPLE PODCASTS</Typography>
                </div>
            </div>
            {/* https://www.youtube.com/watch?v=RDqENFc32aA&ab_channel=GoedAangelegd-NadineSomers */}

            <Typography style={{ maxWidth: hasPhone ? '80%' : '50%', margin: '0 0 5vw 0' }}>{episode.anderetekst}</Typography>
            <Button 
                to='/podcast/ouders'
                text='Terug naar overzicht'
                buttonstyle='secondary'
            />
        </div>
    )
}
