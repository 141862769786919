import React, { useState } from 'react';
import { Typography, Button, Video, Modal } from '../../components';
import { Container, Introduction, Informatie, Downloadable, Aanmelden, IntroductionText } from './styled';
import { Ervaringen } from '../../subpages';

import brochure from '../../assets/documents/brochures/brochure-huisarts.pdf';

export default function Huisarts(props) {
    const [openModalAanmelden, setModalAanmelden] = useState(false);
    const [openModalVragen, setModalVragen] = useState(false);


    const AanmeldenContent = (props) => {
        return (
            <Aanmelden color={props.color}>
                <Typography type='h2' style={{ fontSize: '2em' }}>Aanmelden</Typography>
                <Button text='Direct aanmelden' onClick={() => setModalAanmelden(true)} style={{ margin: 'auto' }} />
            </Aanmelden>
        )
    }

    return (
        <Container>
            <Introduction>
                <IntroductionText>
                    <div>
                        <Typography type='caps' style={{ color: '#145f68', fontSize: '1em', padding: '0' }}>Huisarts</Typography>
                        <Typography type='h1' style={{ color: '#032d34' }}>De nieuwe standaard<br /> in borstvoedingszorg</Typography>
                    </div>
                    <Typography type='h2' style={{ color: '#145f68', fontSize: '1.3em' }}>Betere voorbereiding,<br />minder complicaties</Typography><br />
                    <Button text='Aanmelden' onClick={() => setModalAanmelden(true)} style={{ margin: 'auto' }} />
                </IntroductionText>
                <div style={{ zIndex: '5' }}>
                    <Video videoId='RDqENFc32aA' width={'45vw'} />
                </div>
            </Introduction>
            <Informatie>
                <Downloadable>
                    <Typography type='h2' style={{ fontSize: '2em' }}>Informatie</Typography>
                    <Button href={brochure} text='Brochure Centering Feeding' buttonstyle='secondary' style={{ margin: 'auto' }} target='_blank' download='brochure-Goed-Aangelegd.pdf' />
                </Downloadable>
                <Typography>
                    Behoefte aan meer informatie?<br /> Stel <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setModalVragen(true)}>hier</span> je vraag.
                </Typography>
            </Informatie>
            <Ervaringen />
            <AanmeldenContent color='#F1E3D4' />
            <ModalAanmelden
                open={openModalAanmelden}
                onClose={() => setModalAanmelden(false)}
            />
            <ModalVragen
                open={openModalVragen}
                onClose={() => setModalVragen(false)}
            />
        </Container>
    )
}

export const ModalAanmelden = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <Typography type='h2' as='h3'>Aanmelden</Typography>
            <br />
            <Typography>
                Wat fijn dat je interesse hebt in Centering Feeding.
                Momenteel werken we aan verschillende onderdelen van onze website, waaronder de automatisering achter de knop ‘Aanmelden’.
                We willen je daarom vragen je aanmelding te mailen naar <a href='mailto:info@goedaangelegd.nl'>info@goedaangelegd.nl</a>.
            </Typography>
        </Modal>
    )
}

export const ModalVragen = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <Typography type='h2' as='h3'>Vragen</Typography>
            <br />
            <Typography>
                Wat fijn dat je interesse hebt in Centering Feeding.
                Momenteel werken we aan verschillende onderdelen van onze website, waaronder de automatisering achter de knop ‘Vragen’.
                We willen je daarom vragen om je vraag per mail in te sturen naar <a href='mailto:info@goedaangelegd.nl'>info@goedaangelegd.nl</a>.
            </Typography>
        </Modal>
    )
}